import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { Calendar } from "src/types/fullcalendar-event";
import { managementApiClient } from "./management-api-client";
import { BackendCalendar } from "./content/backend-calendar";


export class CalendarsApi
{
	private readonly client : AxiosInstance;


	public constructor(configuredApiClient : AxiosInstance)
	{
		this.client = configuredApiClient;
	}


	public async getAllCalendars(): Promise<Calendar[]>
	{
		const requestParams = { sort_events_by: "start" };

		const response : AxiosResponse<CalendarsResponse> = await this.client.get("/calendars", { params: requestParams });
        const responseContent = response.data;
		const calendars = responseContent.calendars.map(Calendar.CreateFromBackendObject);

		return calendars;
	}


	public async getCalendar(courseId: string): Promise<Calendar | null>
	{
		let response : AxiosResponse<CalendarsResponse>;
		try
		{
			const requestParams = { sort_events_by: "start" };

			response = await this.client.get(`/calendars/${courseId}`, { params: requestParams });
		}
		catch(error)
		{
			if((error as AxiosError).response?.status === 404) return null;
			else throw error;
		}
        const responseContent = response.data;
		const calendar = Calendar.CreateFromBackendObject(responseContent.calendars[0]);

		return calendar;
	}
}


interface CalendarsResponse
{
    calendars : BackendCalendar[];
}


export const calendarsApi = new CalendarsApi(managementApiClient);