import { DateConversion } from "src/utils/date-conversion";
import { BackendCalendarEvent } from "../api/content/backend-calendar-event";

export class CalendarEvent
{
    public constructor(
        private readonly id: string,
        private readonly names: CalendarEventNames,
        private readonly times: CalendarEventTimes,
        private readonly googleCalendarEventReferences: GoogleCalendarEventReferences
    ) { };


    public static CreateFromBackendObject(backendObject: BackendCalendarEvent): CalendarEvent
    {
        const { id, names, google_calendar_event_references: googleCalendarEventReferences } = backendObject;

        const times : CalendarEventTimes =
        {
            start: new Date(backendObject.times.start),
            end: new Date(backendObject.times.end)
        };

        return new CalendarEvent(id, names, times, googleCalendarEventReferences);
    }


    public get Id(): string
    {
        return this.id;
    }


    public get ShortName(): string | null
    {
        return this.names.short;
    }


    public get StartDateddMM(): string
    {
        return DateConversion.ConvertToddMM(this.times.start);
    }


    public get StartDateddMMyy(): string
    {
        return DateConversion.ConvertToddMMyy(this.times.start);
    }


    public get FullName(): string | null
    {
        return this.names.full;
    }


	public get Start(): Date
    {
        return this.times.start;
    }


	public get End(): Date
    {
        return this.times.end;
    }


    public get GoogleCalendarUrl(): string
    {
        return this.googleCalendarEventReferences.url;
    }


    // function getHasFinished()
    // {        
    //     return (times.end < Date.now());
    // }
}


interface CalendarEventNames
{
    readonly full: string | null;
    readonly short: string | null;
}


interface CalendarEventTimes
{
    readonly start: Date;
    readonly end: Date;
}


interface GoogleCalendarEventReferences
{
    readonly id: string;
    readonly url: string;
}