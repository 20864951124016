import { BackendUserProfile } from "src/api/content/backend-user-profile";


export class UserProfile
{
    public static CreateFromBackendObject(backendObject : BackendUserProfile) : UserProfile
    {
        const { email, name, avatar_url } = backendObject;

        return new UserProfile(email, name, avatar_url);
    }

    
    public get Email() : string | null
    {
        return this.email;
    }


    public get Name() : string | null
    {
        return this.name;
    }


    public get AvatarUrl() : string | null
    {
        return this.avatarUrl;
    }


    private constructor(
        private readonly email : string | null,
        private readonly name : string | null,
        private readonly avatarUrl : string | null,
    ) { }
}