import { createContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Auth0Client, PopupLoginOptions } from '@auth0/auth0-spa-js';
import { auth0Config } from '../config';
import type { User } from '../types/user';
import { loginApi } from "src/api/login-api";

let auth0Client: Auth0Client | null = null;

interface State
{
	isInitialized: boolean;
	isAuthenticated: boolean;
	user: User | null;
}

export interface AuthContextValue extends State
{
	platform: 'Auth0';
	loginWithPopup: (options?: PopupLoginOptions) => Promise<void>;
	logout: () => void;
}

interface AuthProviderProps
{
	children: ReactNode;
}

type InitializeAction = {
	type: 'INITIALIZE';
	payload: {
		isAuthenticated: boolean;
		user: User | null;
	};
};

type LoginAction = {
	type: 'LOGIN';
	payload: {
		user: User;
	};
};

type LogoutAction = {
	type: 'LOGOUT';
};

type RegisterAction = {
	type: 'REGISTER';
};

type Action =
	| InitializeAction
	| LoginAction
	| LogoutAction
	| RegisterAction;

const initialState: State = {
	isAuthenticated: false,
	isInitialized: false,
	user: null
};

const handlers: Record<string, (state: State, action: Action) => State> = {
	INITIALIZE: (state: State, action: InitializeAction): State =>
	{
		const { isAuthenticated, user } = action.payload;

		return {
			...state,
			isAuthenticated,
			isInitialized: true,
			user
		};
	},
	LOGIN: (state: State, action: LoginAction): State =>
	{
		const { user } = action.payload;

		return {
			...state,
			isAuthenticated: true,
			user
		};
	},
	LOGOUT: (state: State): State => ({
		...state,
		isAuthenticated: false,
		user: null
	})
};

const reducer = (state: State, action: Action): State => (
	handlers[action.type] ? handlers[action.type](state, action) : state
);

const AuthContext = createContext<AuthContextValue>({
	...initialState,
	platform: 'Auth0',
	loginWithPopup: () => Promise.resolve(),
	logout: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) =>
{
	const { children } = props;
	const [state, dispatch] = useReducer(reducer, initialState);

	useEffect(() =>
	{
		const initialize = async (): Promise<void> =>
		{
			let isAuthenticated = false;
			let user = null;

			try
			{
				auth0Client = new Auth0Client({
					redirect_uri: window.location.origin,
					...auth0Config
				});

				await auth0Client.checkSession();

				isAuthenticated = await auth0Client.isAuthenticated();

				if(isAuthenticated)
				{
					const auth0User = await auth0Client.getUser();

					const accessToken = await auth0Client.getTokenSilently();					
					const completeUser = await loginApi.login(accessToken);

					// Here you should extract the complete user profile to make it
					// available in your entire app.
					// The auth state only provides basic information.

					user = {
						id: auth0User.sub,
						avatar: completeUser.AvatarUrl,
						email: completeUser.Email,
						name: completeUser.Name,
						plan: 'Kein Plan'
					};

				} else user = null;
			}
			catch(err)
			{
				console.error(err);
				isAuthenticated = false;
			}
			finally
			{
				dispatch({
					type: 'INITIALIZE',
					payload: { isAuthenticated, user }
				});
			}
		};

		initialize();

	}, []);


	const loginWithPopup = async (options?: PopupLoginOptions): Promise<void> =>
	{
		await auth0Client.loginWithPopup(options);

		const isAuthenticated = await auth0Client.isAuthenticated();

		if(isAuthenticated)
		{
			const user = await auth0Client.getUser();

			const accessToken = await auth0Client.getTokenSilently();
			await loginApi.login(accessToken);

			// Here you should extract the complete user profile to make it available in your entire app.
			// The auth state only provides basic information.

			dispatch({
				type: 'LOGIN',
				payload: {
					user: {
						id: user.sub,
						avatar: user.picture,
						email: user.email,
						name: user.name,
						plan: 'Kein Plan'
					}
				}
			});
		}
	};


	const logout = async (): Promise<void> =>
	{
		await loginApi.logout();

		auth0Client.logout({ returnTo: window.location.origin });

		dispatch({ type: 'LOGOUT' });
	};


	return (
		<AuthContext.Provider
			value={{
				...state,
				platform: 'Auth0',
				loginWithPopup,
				logout
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

AuthProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export default AuthContext;
