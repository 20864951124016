import { BackendCalendar } from "src/api/content/backend-calendar";
import { CalendarEvent } from "./calendar-event";


export class Calendar
{
	public constructor(
		private readonly courseId: string,
		private readonly events: CalendarEvent[]
	) { }


	public static CreateFromBackendObject(backendObject: BackendCalendar): Calendar
	{
		const courseId = backendObject.course_id;
		const events = backendObject.events.map(event => CalendarEvent.CreateFromBackendObject(event));

		return new Calendar(courseId, events);
	}


	public get Events(): ReadonlyArray<CalendarEvent>
	{
		return this.events;
	}


	public get Id(): string
	{
		return this.courseId;
	}


	// function getFinishedEvents()
	// {
	// 	return events.filter(event => event.HasFinished);
	// }
}


export interface FullCalendarEvent
{
	id?: string;
	allDay: boolean;
	color?: string;
	description: string;
	end: string | number | Date;
	start: string | number | Date;
	title: string;
}

export type CalendarView =
	| 'dayGridMonth'
	| 'timeGridWeek'
	| 'timeGridDay'
	| 'listWeek';


export function convertCalendarToFullCalendarEvents(calendar: Calendar): FullCalendarEvent[]
{
	return calendar.Events.map(event =>
	{
		const fullCalendarEvent : FullCalendarEvent =
		{
			allDay: false,
			title: event.ShortName,
			color: "#80ff80",
			id: event.Id,
			description: event.FullName,
			start: event.Start,
			end: event.End
		};

		return fullCalendarEvent;
	});
}