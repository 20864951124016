import { AxiosInstance, AxiosResponse } from 'axios';
import { UserProfile } from "src/types/user-profile";
import { BackendUserProfile } from "./content/backend-user-profile";
import { managementApiClient } from "./management-api-client";


export class LoginApi
{
	private readonly client : AxiosInstance;


	public constructor(configuredApiClient : AxiosInstance)
	{
		this.client = configuredApiClient;
	}


	public async login(accessToken : string): Promise<UserProfile>
	{
		const response : AxiosResponse<LoginPostRequestResponse> = await this.client.post("/login", { access_token: accessToken });
        const backendUserProfile = response.data.user;
		const userProfile = UserProfile.CreateFromBackendObject(backendUserProfile);

		return userProfile;
	}


	public async logout(): Promise<void>
	{
		await this.client.delete("/login");
	}
}


interface LoginPostRequestResponse
{
	user: BackendUserProfile;
}


export const loginApi = new LoginApi(managementApiClient);


