import moment from "moment";
import { Moment } from "moment-timezone";

export class DateConversion
{
    public static readonly MsPerSec = 1000;
    public static readonly MsPerMin = DateConversion.MsPerSec * 60;
    public static readonly MsPerHour = DateConversion.MsPerMin * 60;
    public static readonly MsPerDay = DateConversion.MsPerHour * 24;
    public static readonly MsPerWeek = DateConversion.MsPerDay * 7;


    public static ConvertDateToInputFormat(date : Moment) : string
    {
        return date.format("yyyy-MM-DD[T]HH:mm");
    }


    public static ConvertToWeekdayNameAndTime(date : Date) : string
    {
        const dateMoment = moment(date);
        dateMoment.locale(navigator.languages[0]);
        
        return dateMoment.format("dd HH:mm");
    }


    public static ConvertToddMMyyHHmm(date : Date) : string
    {
        const ddMMyy = DateConversion.ConvertToddMMyy(date);
        const HHmm = DateConversion.ConvertToHHmm(date);

        const ddMMyyHHmm = `${ddMMyy} ${HHmm}`;

        return ddMMyyHHmm;
    }


    public static ConvertToddMMyy(date : Date) : string
    {
        const dayOfTheMonth = DateConversion.GetPaddedDayOfTheMonth(date);

        const month = DateConversion.GetPaddedMonth(date);

        const yearNumber = date.getFullYear();
        let year = yearNumber.toString();
        year = year.substr(year.length - 2, 2);

        const ddmmyy = `${dayOfTheMonth}.${month}.${year}`;
        return ddmmyy;
    }


    public static ConvertToddMM(date : Date) : string
    {
        const dayOfTheMonth = DateConversion.GetPaddedDayOfTheMonth(date);

        const month = DateConversion.GetPaddedMonth(date);

        const ddmm = `${dayOfTheMonth}.${month}.`;
        return ddmm;
    }


    public static ConvertToyyyyMMdd(date : Date, separatorString : string = '') : string
    {
        const dayOfTheMonth = DateConversion.GetPaddedDayOfTheMonth(date);
        const month = DateConversion.GetPaddedMonth(date);
        const year = date.getFullYear();

        const yyyyMMddWithSeparator = (year + separatorString + month + separatorString + dayOfTheMonth);
        return yyyyMMddWithSeparator;
    }


    public static ConvertToHHmmStartEnd(startDate : Date, endDate : Date) : string
    {
        const startString = DateConversion.ConvertToHHmm(startDate);
        const endString = DateConversion.ConvertToHHmm(endDate);

        const startEndTimeString = `${startString} - ${endString}`;
        return startEndTimeString;
    }


    public static ConvertToHHmm(date : Date) : string
    {
        let hoursString = date.getHours().toString();
        hoursString = DateConversion.LeftZeroPadToTwoDigits(hoursString);

        let minutesString = date.getMinutes().toString();
        minutesString = DateConversion.LeftZeroPadToTwoDigits(minutesString);

        const hhmmString = `${hoursString}:${minutesString}`;
        return hhmmString;
    }


    public static ShiftToLocalTimezone(date : Date) : Date
    {
        return new Date(date.getTime() + DateConversion.GetTimezoneOffsetMs(date));
    }


    public static ShiftToSameTimezone(targetTimezoneDate : Date, shiftingDate : Date) : Date
    {
        const targetTimeZoneOffsetMs = DateConversion.GetTimezoneOffsetMs(targetTimezoneDate);
        const shiftingDateTimezoneOffsetMs = DateConversion.GetTimezoneOffsetMs(shiftingDate);
        const timezoneDifferenceMs = (shiftingDateTimezoneOffsetMs - targetTimeZoneOffsetMs);

        const shiftingDateMs = shiftingDate.getTime();
        const shiftedDateMs = shiftingDateMs + timezoneDifferenceMs;
        const shiftedDate = new Date(shiftedDateMs);

        return shiftedDate;
    }


    private static GetTimezoneOffsetMs(date)
    {
        return (date.getTimezoneOffset() * 60 * 1000);
    }


    private static GetPaddedDayOfTheMonth(date)
    {
        let dayOfTheMonth = date.getDate().toString();
        dayOfTheMonth = DateConversion.LeftZeroPadToTwoDigits(dayOfTheMonth);

        return dayOfTheMonth;
    }


    private static GetPaddedMonth(date)
    {
        let month = (date.getMonth() + 1).toString();
        month = DateConversion.LeftZeroPadToTwoDigits(month);

        return month;
    }


    private static LeftZeroPadToTwoDigits(numberString)
    {
        if(numberString.length === 2) return numberString;
        else return ('0' + numberString);
    }
    
}