import { Suspense, lazy } from 'react';
import type { PartialRouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import BrowseLayout from './components/BrowseLayout';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Browse pages

const Browse = Loadable(lazy(() => import('./pages/browse/Browse')));
const BrowseButtons = Loadable(lazy(() => import('./pages/browse/BrowseButtons')));
const BrowseCharts = Loadable(lazy(() => import('./pages/browse/BrowseCharts')));
const BrowseColors = Loadable(lazy(() => import('./pages/browse/BrowseColors')));
const BrowseDetailLists = Loadable(lazy(() => import('./pages/browse/BrowseDetailLists')));
const BrowseForms = Loadable(lazy(() => import('./pages/browse/BrowseForms')));
const BrowseGridLists = Loadable(lazy(() => import('./pages/browse/BrowseGridLists')));
const BrowseGroupedLists = Loadable(lazy(() => import('./pages/browse/BrowseGroupedLists')));
const BrowseInputs = Loadable(lazy(() => import('./pages/browse/BrowseInputs')));
const BrowseModals = Loadable(lazy(() => import('./pages/browse/BrowseModals')));
const BrowseQuickStats = Loadable(lazy(() => import('./pages/browse/BrowseQuickStats')));
const BrowseTables = Loadable(lazy(() => import('./pages/browse/BrowseTables')));
const BrowseTypography = Loadable(lazy(() => import('./pages/browse/BrowseTypography')));

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));

// Dashboard pages

const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const Analytics = Loadable(lazy(() => import('./pages/dashboard/Analytics')));
const Calendar = Loadable(lazy(() => import('./pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('./pages/dashboard/Kanban')));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Projects pages

const ProjectBrowse = Loadable(lazy(() => import('./pages/dashboard/ProjectBrowse')));
const ProjectCreate = Loadable(lazy(() => import('./pages/dashboard/ProjectCreate')));
const ProjectDetails = Loadable(lazy(() => import('./pages/dashboard/ProjectDetails')));
const ProjectEdit = Loadable(lazy(() => import('./pages/dashboard/ProjectEdit')));

// Social pages

const SocialTeam = Loadable(lazy(() => import('./pages/dashboard/SocialTeam')));

// Settings pages

const Settings = Loadable(lazy(() => import('./pages/dashboard/Settings')));
const Templates = Loadable(lazy(() => import('./pages/dashboard/Templates')));

const routes: PartialRouteObject[] = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'password-reset',
        element: <PasswordReset />
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        )
      },
      {
        path: 'register-unguarded',
        element: <Register />
      },
      {
        path: 'verify-code',
        element: <VerifyCode />
      }
    ]
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <ProjectBrowse />
      },
      {
        path: 'account',
        element: <Account />
      },
      {
        path: 'calendar',
        element: <Calendar />
      },
      {
        path: 'kanban',
        element: <Kanban />
      },
      {
        path: 'projects',
        children: [
          {
            path: 'browse',
            element: <ProjectBrowse />
          },
          {
            path: 'new',
            element: <ProjectCreate />
          },
          {
            path: ':projectId',
            children: [
              {
                path: '/',
                element: <ProjectDetails />
              },
              {
                path: 'edit',
                element: <ProjectEdit />
              }
            ]
          }
        ]
      },
      {
        path: 'settings',
        element: <Settings />
      },
      {
        path: 'templates',
        element: <Templates />
      },
      {
        path: 'social',
        element: <SocialTeam />
      }
    ]
  },
  {
    path: '/',
    element: (
      <Navigate
        to="/dashboard"
        replace
      />
    )
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: 'browse',
        element: <BrowseLayout />,
        children: [
          {
            path: '/',
            element: <Browse />
          },
          {
            path: '/buttons',
            element: <BrowseButtons />
          },
          {
            path: '/inputs',
            element: <BrowseInputs />
          },
          {
            path: '/charts',
            element: <BrowseCharts />
          },
          {
            path: '/colors',
            element: <BrowseColors />
          },
          {
            path: '/data-display/detail-lists',
            element: <BrowseDetailLists />
          },
          {
            path: '/data-display/quick-stats',
            element: <BrowseQuickStats />
          },
          {
            path: '/data-display/tables',
            element: <BrowseTables />
          },
          {
            path: '/forms',
            element: <BrowseForms />
          },
          {
            path: '/modals',
            element: <BrowseModals />
          },
          {
            path: '/lists/grouped-lists',
            element: <BrowseGroupedLists />
          },
          {
            path: '/lists/grid-lists',
            element: <BrowseGridLists />
          },
          {
            path: '/typography',
            element: <BrowseTypography />
          }
        ]
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
